import { CharityType, CreditorType } from "@gocardless/api/dashboard/types";
import { CreditorDetailUpdateRequestBody } from "@gocardless/api/dashboard/creditor-detail";
import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import {
  useBillingContactCreate,
  useBillingContactList,
  useBillingContactUpdate,
} from "@gocardless/api/dashboard/billing-contact";

import { useComplete } from "../common/validators/useComplete";
import { useSetupData } from "../common/hooks/useSetupData";
import { AddressConfig } from "../common/components/Address/AddressSearch";
import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";
import { SetupPages } from "../common/config/types";
import { removeEmptyObjProperties } from "../common/helpers/removeEmptyObjProperties";

import { useIsImpersonation } from "src/queries/user";
import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";
import {
  aboutYouBuilder,
  parseAboutYou,
} from "src/components/routes/Setup/common/builders/about-you";
import { Route } from "src/common/routing";
import { parseBillingContact } from "src/components/routes/Setup/contact-details/parseContact";

export interface UseAboutYou extends UseSetupPage {
  aboutYou: Partial<AboutYouConfig>;
  countryCode: string;
  submitAboutYou: (data: AboutYouConfig) => void;
  shouldUpdateUserAmlEntity: boolean;
}

export type IndividualPersonType = {
  given_name: string;
  family_name: string;
  date_of_birth: string;
  place_of_birth?: string | null;
} & AddressConfig &
  Required<
    Pick<AddressConfig, "street" | "city" | "postal_code" | "country_code">
  >;
export interface AboutYouConfig {
  individual_number?: string;
  individual_number_type?: string;
  person: IndividualPersonType;
  account_holder_is_trustee?: boolean;
  creditor_type?: CreditorType | null;
  charity_type?: CharityType;
  phone_number?: string;
}

export function useAboutYou({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): UseAboutYou {
  const { creditorDetails } = useSetupData();
  const {
    loaded: paobVerificationLoaded,
    gcSasVerificationEnabled,
    isPrimaryUser,
    userAmlEntity,
    updateUserAmlEntity,
  } = usePaobVerification({ onSuccess, onError });

  const { data: billingContacts, revalidate: revalidateBilling } =
    useBillingContactList();
  const billingContact = parseBillingContact(billingContacts);
  const billingPhoneNumber = billingContact?.phoneNumber;
  const [updateBillingContact] = useBillingContactUpdate(billingContact.id, {
    onSuccess: () => revalidateBilling(),
    onError,
  });
  const [createBillingContact] = useBillingContactCreate({
    onSuccess: () => revalidateBilling(),
    onError,
  });

  const { data: userData } = useUserShowSelf();
  const isImpersonation = useIsImpersonation();
  const { areAllRequiredFieldsComplete } = useComplete(SetupPages.ABOUT_YOU);
  const trustees = creditorDetails?.detail?.trustees || [];
  const countryCode = creditorDetails?.detail?.country_code ?? "";
  const aboutYou = {
    ...parseAboutYou(
      creditorDetails,
      isImpersonation ? undefined : userData?.users,
      countryCode
    ),
    phone_number: billingPhoneNumber,
  };
  const creditorType = creditorDetails?.creditor_type;
  const shouldUpdateUserAmlEntity =
    (creditorType === CreditorType.Individual ||
      creditorType === CreditorType.Charity ||
      creditorType === CreditorType.Partnership) &&
    gcSasVerificationEnabled &&
    isPrimaryUser;
  const { updateCreditorDetail } = useSetupData(
    shouldUpdateUserAmlEntity ? () => {} : onSuccess,
    onError
  );

  const [updateUser] = useUserUpdateSelf();

  const submitAboutYou = async (data: AboutYouConfig) => {
    if (!creditorType) return;

    const put: CreditorDetailUpdateRequestBody = {
      creditor_type: creditorType,
      detail: aboutYouBuilder(creditorType)(data, countryCode, trustees),
    };

    await updateCreditorDetail(removeEmptyObjProperties(put)).then(async () => {
      if (shouldUpdateUserAmlEntity) {
        updateUserAmlEntity({
          given_name: data.person.given_name,
          family_name: data.person.family_name,
          date_of_birth: data.person.date_of_birth,
          place_of_birth: data.person.place_of_birth,
          country_code: countryCode,
        });
      }
    });

    if (!userData?.users?.family_name && !userData?.users?.given_name) {
      await updateUser({
        family_name: data.person.family_name,
        given_name: data.person.given_name,
      });
    }

    if (data.phone_number) {
      if (billingContact.id) {
        await updateBillingContact({
          phone_number: data.phone_number,
        });
      } else {
        await createBillingContact({
          given_name: data.person.given_name,
          family_name: data.person.family_name,
          email: userData?.users?.email || "",
          phone_number: data.phone_number,
          locale: userData?.users?.locale || "",
        });
      }
    }
  };

  return {
    loaded:
      !!creditorDetails &&
      !!userData &&
      paobVerificationLoaded &&
      !!billingContacts,
    skip:
      creditorType !== CreditorType.Individual &&
      creditorType !== CreditorType.Charity,
    completed:
      areAllRequiredFieldsComplete({
        creditor_type: creditorType,
        geo: countryCode,
        ...aboutYou.person,
      }) &&
      (!shouldUpdateUserAmlEntity || !!userAmlEntity?.place_of_birth),
    route: Route.AboutYou,
    aboutYou,
    countryCode,
    submitAboutYou,
    shouldUpdateUserAmlEntity,
  };
}
