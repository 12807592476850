import { useMemo, useState } from "react";
import PhoneInput, {
  Country,
  getCountries,
  DefaultInputComponentProps,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FieldError } from "react-hook-form";
import { Color, Input, useTheme } from "@gocardless/flux-react";

import { getInputErrorStatus } from "src/utils/inputValidation";
import {
  CountryCodeNotSupported,
  CountryCodeSupported,
  CountryCodes,
} from "src/common/country";

export interface PhoneNumberProps {
  id?: string;
  defaultCountry?: CountryCodes | string;
  name?: string;
  required?: boolean;
  error?: FieldError;
  className?: string;
  supportedGeosOnly?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
}

// Fixes showing international flag for valid country numbers:
const getCountryCode = (number: string | undefined | null): string | null => {
  if (!number) return null;

  const phoneNumber = parsePhoneNumber(number);

  // Docs (https://www.npmjs.com/package/react-phone-number-input) claim this should be present:
  if (phoneNumber?.country) {
    return phoneNumber.country;
  }

  // But it's not, so we're doing this:
  const callingCode = phoneNumber?.countryCallingCode;
  if (callingCode) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const countryCode = (phoneNumber as any)?.metadata?.country_calling_codes[
      callingCode
    ][0];
    return countryCode || null;
  }

  return null;
};

const PhoneNumber = ({
  id,
  defaultCountry: defaultCountryGeo = CountryCodes.GB,
  name = "phone_number",
  required,
  error,
  className,
  supportedGeosOnly,
  onChange,
  defaultValue,
  ...fieldProps
}: PhoneNumberProps) => {
  const [numberValue, setNumberValue] = useState(defaultValue);
  const [country, setCountry] = useState(
    getCountryCode(defaultValue) || defaultCountryGeo
  );

  const { theme } = useTheme();

  const countryList: DefaultInputComponentProps["countries"] = useMemo(() => {
    const supportedCountryCodes = Object.values(CountryCodeSupported);
    const extraSupportedCountriesForPhone = [
      CountryCodes.GF,
      CountryCodes.GP,
      CountryCodes.IL,
      CountryCodes.LV,
      CountryCodes.RE,
      CountryCodes.YT,
    ];

    if (supportedGeosOnly) {
      return getCountries().filter(
        (code) =>
          supportedCountryCodes.includes(code as CountryCodeSupported) ||
          extraSupportedCountriesForPhone.includes(code as CountryCodes)
      );
    } else {
      return getCountries().filter(
        (code) => !CountryCodeNotSupported.includes(code as CountryCodes)
      );
    }
  }, [supportedGeosOnly]);

  return (
    <PhoneInput
      {...fieldProps}
      type="tel"
      name={name}
      value={numberValue}
      countries={countryList}
      onCountryChange={(newCountry) => {
        // Reset their number when they change their country code from what they currently have:
        if (newCountry && country && newCountry !== country) {
          setCountry(newCountry);
          setNumberValue("");
        }
      }}
      defaultCountry={country as Country}
      id={id}
      inputComponent={Input}
      status={getInputErrorStatus(!!error)}
      required={required}
      international
      countryCallingCodeEditable={false}
      css={{
        position: "relative",
        marginTop: "8px",
        width: "100%",
        ".PhoneInputCountry": {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "16px",
          "--PhoneInputCountrySelectArrow-borderWidth": "2px",
          ".PhoneInputCountryIcon--border": {
            boxShadow: "initial",
            borderRadius: "4px",
            overflow: "hidden",
          },
          ".PhoneInputCountrySelectArrow": {
            opacity: 1,
            width: "0.5em",
            height: "0.5em",
            marginLeft: "0.5em",
            marginBottom: "0.2em",
            borderColor: theme.color(Color.Greystone_DarkMatter),
          },
          "~ div": {
            paddingLeft: "48px",
            width: "100%",
          },
        },
      }}
      className={className}
      onChange={(data) => {
        const formattedNumber = data || "";
        setNumberValue(formattedNumber);
        return onChange && onChange(formattedNumber);
      }}
    />
  );
};

export default PhoneNumber;
