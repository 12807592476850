import { Field, Hint, Label } from "@gocardless/flux-react";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { Field as Config, FieldArrayProps } from "../config/types";

import { error } from "./helpers";

import { AboutYouConfig } from "src/components/routes/Setup/about-you/useAboutYou";
import PhoneNumber from "src/components/ui/phone-number-input/PhoneNumber";
import { CountryCodes } from "src/common/country";
import { ToTranslate } from "src/components/i18n";

type PhoneNumberFieldProps = {
  defaultValue?: string;
  defaultCountry?: CountryCodes;
} & FieldArrayProps;

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  defaultValue,
  defaultCountry,
}: PhoneNumberFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AboutYouConfig>();

  const presenceCheckValidation = i18n._(
    t({
      id: "phone-number.field-validation-warning",
      message: "Please enter a valid phone number",
    })
  );

  const requiredValidation = i18n._(
    t({
      id: "phone-number.field-required-warning",
      message: "Phone number is required",
    })
  );

  return (
    <Controller
      name="phone_number"
      defaultValue={defaultValue}
      control={control}
      rules={{
        required: requiredValidation,
        validate: {
          presence: (value) =>
            value && isPossiblePhoneNumber(value)
              ? true
              : presenceCheckValidation,
        },
      }}
      render={({ field }) => (
        <Field>
          <Label htmlFor="phone_number">
            <ToTranslate>Phone number</ToTranslate>
          </Label>
          <Hint>
            <ToTranslate>
              This is not required for verification purposes but we may use it
              to contact you if you require support
            </ToTranslate>
          </Hint>
          <PhoneNumber
            id={field.name}
            name={field.name}
            onChange={field.onChange}
            className="fs-exclude"
            error={errors.phone_number}
            defaultValue={field.value}
            defaultCountry={defaultCountry}
          />
          {error(errors, "phone_number")}
        </Field>
      )}
    />
  );
};

export const config: Config = {
  name: "phone_number",
  required: false,
  displayName: "Phone Number",
  component: PhoneNumberField,
};

export default PhoneNumberField;
